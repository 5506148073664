import axios from "@/plugins/axios";
import {
  parse_work_to_server,
  parse_work_to_server_for_update,
} from "@/services/work/parsers";

/**
 * Данные графика заказов по годам
 * @param {boolean} money
 * @return {Promise<Object>}
 */
export const API_work_overview_years = (money = true) => {
  return axios(`/worksOverview/years`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
    params: {
      money: money,
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Данные графика заказов по месяцам
 * @param {number} year
 * @return {Promise<Object>}
 */
export const API_work_overview_months = (year) => {
  return axios(`/worksOverview/year/${year}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Список работ на выбранный месяц
 * @param {number} year
 * @param {number} month
 * @param {string} kind
 * @return {Promise<WorkServer[]>}
 */
export const API_work_list = (year, month, kind) => {
  return axios(
    `/work?year=${year}&month=${month}&workTimeScheduleKind=${kind}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }
  )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Создание работы
 * @param  {Object} value
 * @return {Promise<Object>}
 */
export const API_work_create = (value) => {
  return axios("/work", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: parse_work_to_server(value),
  })
    .then((response) => {
      return { id: response.data.data.id };
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Обновление работы
 * @param  {number} workId
 * @param  {Object} value
 * @return {Promise<Object>}
 */
export const API_work_update = (workId, value) => {
  return axios(`/work/${workId}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    data: parse_work_to_server_for_update(value),
  })
    .then((response) => {
      return { id: response.data.data.id };
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Просмотр работы
 * @param  {number|string} workId
 * @return {Promise<WorkServer>}
 */
export const API_work_view = (workId) => {
  return axios(`/work/${workId}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Просмотр работы
 * @param  {number} workId
 */
export const API_work_delete = (workId) => {
  return axios(`/work/${workId}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
    },
  })
    .then(() => {
      return "ok";
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Создание нормативного расчета
 * @param {number|string} workId
 * @param {Object} value
 */
export const API_workDesign_create = (workId, value) => {
  value.work_id = workId;
  value.WorkDocument.Document.IdentifiedObject.name =
    value.WorkDocument.Document.title;
  delete value.WorkDocument.Document.author;
  return axios("/design", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: value,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const API_workDesign_view = (designId) => {
  return axios(`/design/${designId}`, {
    method: "GET",
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const API_workDesign_update = (designId, workId, value) => {
  value.work_id = workId;
  return axios(`/design/${designId}`, {
    method: "PUT",
    data: value,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const API_workDesign_delete = (designId) => {
  return axios(`/design/${designId}`, {
    method: "DELETE",
  })
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
};

export const API_workDesign_oldWorkTasks_view = (designId) => {
  return axios(`/design/${designId}/oldWorkTasks`, {
    method: "GET",
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const API_workDesign_set_active = (designId) => {
  return axios(`/design/${designId}/active`, {
    method: "GET",
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const API_workTask_create = (workId, value) => {
  value.work_id = workId;
  delete value.BaseWork.Document.author;
  delete value.totalCost;
  return axios("/workTask", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: value,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const API_workTask_view = (workTaskId) => {
  return axios(`/workTask/${workTaskId}`, {
    method: "GET",
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const API_workTask_update = (workTaskId, workId, value) => {
  value.work_id = workId;
  delete value.BaseWork.Document.author;
  delete value.totalCost;
  return axios(`/workTask/${workTaskId}`, {
    method: "PUT",
    data: value,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const API_workTask_delete = (workTaskId) => {
  return axios(`/workTask/${workTaskId}`, {
    method: "DELETE",
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};
