export let createWorkSchema = {
  BaseWork: {
    Document: {
      title: "",
      IdentifiedObject: {
        name: "", //дубляж title
        description: "",
      },
      Issuer: {
        DocumentPersonRole: {
          PersonRole: {
            person_id: 1,
            IdentifiedObject: {
              name: "Ответственный по заказу",
              enproCode: "КОДРОЛИ0005",
            },
          },
        },
      },
    },
    TimeSchedules: [
      {
        TimeSchedule: {
          scheduleInterval: {
            end: "2022-01-31 10:25:15",
            start: "2022-01-15 10:25:15",
          },
        },
        kind_id: 1,
      },
    ],
    kind_id: 1,
    status_kind_id: 9,
    priority_id: 1,
    WorkLocation: {
      location_id: "ad333590-4fc7-420c-ae55-62b89295ce26",
    },
  },
  WorkTasks: [],
};

const workIdentifiedSchema = {
  type: "object",
  properties: {
    id: {
      type: "id",
      path: "WorkIdentifiedObject.id",
    },
    IdentifiedObject: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "WorkIdentifiedObject.IdentifiedObject.id",
        },
        name: {
          type: "value",
          path: "WorkIdentifiedObject.IdentifiedObject.name",
        },
      },
    },
  },
};

export let createWorkTaskSchema = {
  BaseWork: {
    Document: {
      title: "Замена опоры 21",
      IdentifiedObject: {
        name: "Замена опоры 21",
        description: "",
      },
    },
    TimeSchedules: [
      {
        TimeSchedule: {
          scheduleInterval: {
            end: "2022-01-31 10:25:15",
            start: "2022-01-15 10:25:15",
          },
        },
        work_id: 1,
      },
    ],
  },
  MaterialItems: [
    {
      type_material_id: 1,
      quantity: { value: 500 },
      IdentifiedObject: {
        name: "", //Подставлять TypeMaterial.WorkDocument.Document.IdentifiedObject.name
      },
    },
  ],
};

export let createDesignLocation = {
  WorkIdentifiedObject: {
    IdentifiedObject: {
      name: "", //CompatibleUnit.WorkDocument.Document.IO.name
      enproCode: "", //CompatibleUnit.Document.IO.enproCode
    },
  },
  compatible_unit_id: null, //id тех карты
  cu_action_id: null,
  DesignLocation: {
    spanLength: {
      value: null, //Физический объем (ручной ввод)
    },
    WorkLocations: [
      //{
      //  location_id: null, //Техническое место
      //},
    ],
  },
  WorkTasks: [
    {
      WorkTask: {
        Assets: [
          // {
          //   id: null, // asset id
          // },
        ],
      },
      MiscCostItems: [
        // {
        //   id: 0,
        //   WorkIdentifiedObject: {
        //     id: 0,
        //     IdentifiedObject: {
        //       id: 0,
        //       name: "string",
        //       description: "string",
        //       enproCode: "string",
        //       names: [
        //         {
        //           id: 0,
        //           name: "string",
        //         },
        //       ],
        //     },
        //   },
        //   costPerUnit: {
        //     id: 0,
        //     value: 0,
        //   },
        //   enproCost: {
        //     id: 0,
        //     value: 0,
        //   },
        //   quantity: {
        //     id: 0,
        //     value: 0,
        //   },
        // },
      ],
    },
  ],
  ConditionFactors: [
    //{
    //  id: null, // Поправочный коэффициент
    //},
  ],
  cuQuantity: {
    text: "",
    value: null, //Кол-во единиц работ в расчетном месте (ручной ввод)
  },
};

export let createDesign = {
  WorkDocument: {
    Document: {
      title: "", //Название
      IdentifiedObject: {
        name: "", //Название
        description: "",
      },
    },
  },
  kind_id: 1, //Тип расчета
  costEstimate: {
    value: 0, //Расчетная стоимость заказа
  },
  work_id: 1,
  DesignLocations: [
    {
      WorkLocations: [
        {
          location_id: 1, //Объект (всегда 1 элемент)
        },
      ],
    },
  ],
  DesignLocationCUs: [], // createDesignLocation от тех карты
};

export let designLocationCU = {
  type: "object",
  properties: {
    id: {
      type: "id",
      path: "id",
    },
    WorkIdentifiedObject: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "WorkIdentifiedObject.id",
        },
        IdentifiedObject: {
          type: "object",
          properties: {
            id: {
              type: "id",
              path: "WorkIdentifiedObject.IdentifiedObject.id",
            },
            name: {
              type: "value",
              path: "WorkIdentifiedObject.IdentifiedObject.name",
            },
            enproCode: {
              type: "value",
              path: "WorkIdentifiedObject.IdentifiedObject.enproCode",
            },
          },
        },
      },
    },
    compatible_unit_id: {
      type: "value",
      path: "compatible_unit_id",
    },
    cu_action_id: {
      type: "value",
      path: "cuAction.id",
    },
    DesignLocation: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "DesignLocation.id",
        },
        spanLength: {
          type: "object",
          properties: {
            id: {
              type: "id",
              path: "DesignLocation.spanLength.id",
            },
            value: {
              type: "value",
              path: "DesignLocation.spanLength.value",
            },
          },
        },
        WorkLocations: {
          type: "array",
          path: "DesignLocation.WorkLocations",
          items: {
            type: "object",
            properties: {
              id: {
                type: "id",
                path: "id",
              },
              location_id: {
                type: "value",
                path: "Location.id",
              },
            },
          },
        },
      },
    },
    ConditionFactors: {
      type: "array",
      path: "ConditionFactors",
      items: {
        type: "object",
        properties: {
          id: {
            type: "value",
            path: "id",
          },
        },
      },
    },
    cuQuantity: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "cuQuantity.id",
        },
        value: {
          type: "value",
          path: "cuQuantity.value",
        },
      },
    },
    WorkTasks: {
      type: "array",
      path: "WorkTasks",
      items: {
        type: "object",
        properties: {
          id: {
            type: "id",
            path: "id",
          },
          WorkTask: {
            type: "object",
            properties: {
              id: {
                type: "id",
                path: "WorkTask.id",
              },
              Assets: {
                type: "array",
                path: "WorkTask.Assets",
                items: {
                  type: "object",
                  properties: {
                    id: {
                      type: "value",
                      path: "id",
                    },
                    class: {
                      type: "value",
                      path: "class",
                    },
                  },
                },
              },
            },
          },
          MiscCostItems: {
            type: "array",
            path: "MiscCostItems",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "id",
                  path: "id",
                },
                WorkIdentifiedObject: {
                  type: "object",
                  properties: {
                    id: {
                      type: "id",
                      path: "WorkIdentifiedObject.id",
                    },
                    IdentifiedObject: {
                      type: "object",
                      properties: {
                        id: {
                          type: "id",
                          path: "WorkIdentifiedObject.IdentifiedObject.id",
                        },
                        name: {
                          type: "value",
                          path: "WorkIdentifiedObject.IdentifiedObject.name",
                        },
                      },
                    },
                  },
                },
                costPerUnit: {
                  type: "object",
                  properties: {
                    id: {
                      type: "id",
                      path: "costPerUnit.id",
                    },
                    value: {
                      type: "value",
                      path: "costPerUnit.value",
                    },
                  },
                },
                quantity: {
                  type: "object",
                  properties: {
                    id: {
                      type: "id",
                      path: "quantity.id",
                    },
                    value: {
                      type: "value",
                      path: "quantity.value",
                    },
                  },
                },
                enproCost: {
                  type: "object",
                  properties: {
                    id: {
                      type: "id",
                      path: "enproCost.id",
                    },
                    value: {
                      type: "value",
                      path: "enproCost.value",
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export let updateDesign = {
  type: "object",
  properties: {
    id: {
      type: "id",
      path: "id",
    },
    WorkDocument: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "WorkDocument.id",
        },
        Document: {
          type: "object",
          properties: {
            id: {
              type: "id",
              path: "WorkDocument.Document.id",
            },
            title: {
              type: "value",
              path: "WorkDocument.Document.title",
            },
            IdentifiedObject: {
              type: "object",
              properties: {
                id: {
                  type: "id",
                  path: "WorkDocument.Document.IdentifiedObject.id",
                },
                name: {
                  type: "value",
                  path: "WorkDocument.Document.title",
                },
                description: {
                  type: "value",
                  path: "WorkDocument.Document.IdentifiedObject.description",
                },
              },
            },
            author: {
              type: "value",
              path: "WorkDocument.Document.Author.DocumentPersonRole.PersonRole.Person",
            },
            createdDateTime: {
              type: "value",
              path: "WorkDocument.Document.createdDateTime",
            },
            lastModifiedDateTime: {
              type: "value",
              path: "WorkDocument.Document.lastModifiedDateTime",
            },
          },
        },
      },
    },
    kind_id: {
      type: "value",
      path: "kind.id",
    },
    // work_id: {
    //   type: "value",
    //   path: "id"
    // },
    costEstimate: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "costEstimate.id",
        },
        value: {
          type: "value",
          path: "costEstimate.value",
        },
      },
    },
    enproActive: {
      type: "value",
      path: "enproActive",
    },
    DesignLocations: {
      type: "array",
      path: "DesignLocations",
      items: {
        type: "object",
        properties: {
          id: {
            type: "id",
            path: "id",
          },
          WorkLocations: {
            type: "array",
            path: "WorkLocations",
            items: {
              type: "object",
              properties: {
                location_id: {
                  type: "value",
                  path: "Location.id",
                },
              },
            },
          },
        },
      },
    },
    DesignLocationCUs: {
      type: "array",
      path: "DesignLocationCUs",
      items: {
        ...designLocationCU,
      },
    },
  },
};

export let updateWorkTaskMaterialItems = {
  type: "object",
  properties: {
    id: {
      type: "value",
      path: "id",
    },
    type_material_id: {
      type: "value",
      path: "TypeMaterial.id",
    },
    quantity: {
      type: "object",
      properties: {
        value: {
          type: "value",
          path: "quantity.value",
        },
      },
    },
    IdentifiedObject: {
      type: "object",
      properties: {
        id: {
          type: "value",
          path: "IdentifiedObject.id",
        },
        name: {
          type: "value",
          path: "IdentifiedObject.name",
        },
      },
    },
  },
};

export let updateWorkTaskLaborItem = {
  type: "object",
  properties: {
    id: {
      type: "id",
      path: "id",
    },
    WorkIdentifiedObject: workIdentifiedSchema,
    cost: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "cost.id",
        },
        value: {
          type: "value",
          path: "cost.value",
        },
      },
    },
    laborDuration: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "laborDuration.id",
        },
        value: {
          type: "value",
          path: "laborDuration.value",
        },
      },
    },
    laborRate: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "laborRate.id",
        },
        value: {
          type: "value",
          path: "laborRate.value",
        },
      },
    },
    status_id: {
      type: "value",
      path: "status.id",
    },
    ErpPersons: {
      type: "array",
      path: "ErpPersons",
      items: {
        type: "object",
        properties: {
          id: {
            type: "value",
            path: "id",
          },
        },
      },
    },
  },
};

export let updateWorkTaskWorkEquipmentItems = {
  type: "object",
  properties: {
    id: {
      type: "id",
      path: "id",
    },
    WorkIdentifiedObject: workIdentifiedSchema,
    equipCode: {
      type: "value",
      path: "equipCode",
    },
    costType: {
      type: "value",
      path: "costType",
    },
    cost: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "cost.id",
        },
        value: {
          type: "value",
          path: "cost.value",
        },
      },
    },
    enproDuration: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "enproDuration.id",
        },
        value: {
          type: "value",
          path: "enproDuration.value",
        },
      },
    },
    rate: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "rate.id",
        },
        value: {
          type: "value",
          path: "rate.value",
        },
      },
    },
    status_id: {
      type: "value",
      path: "status.id",
    },
    // work_task_id: {
    //   type: "value",
    //   path: ""
    // }
  },
};

export let updateWorkTaskMiscCostItems = {
  type: "object",
  properties: {
    id: {
      type: "id",
      path: "id",
    },
    WorkIdentifiedObject: workIdentifiedSchema,
    costPerUnit: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "costPerUnit.id",
        },
        value: {
          type: "value",
          path: "costPerUnit.value",
        },
      },
    },
    enproCost: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "enproCost.id",
        },
        value: {
          type: "value",
          path: "enproCost.value",
        },
      },
    },
    quantity: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "quantity.id",
        },
        value: {
          type: "value",
          path: "quantity.value",
        },
      },
    },
  },
};

export let updateWorkTask = {
  type: "object",
  properties: {
    id: {
      type: "id",
      path: "id",
    },
    work_id: {
      type: "value",
      path: "work_id",
    },
    instruction: {
      type: "value",
      path: "instruction",
    },
    BaseWork: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "BaseWork.id",
        },
        Document: {
          type: "object",
          properties: {
            id: {
              type: "id",
              path: "BaseWork.Document.id",
            },
            IdentifiedObject: {
              type: "object",
              properties: {
                id: {
                  type: "id",
                  path: "BaseWork.Document.IdentifiedObject.id",
                },
                name: {
                  type: "value",
                  path: "BaseWork.Document.IdentifiedObject.name",
                },
                description: {
                  type: "value",
                  path: "BaseWork.Document.IdentifiedObject.description",
                },
                enproCode: {
                  type: "value",
                  path: "BaseWork.Document.IdentifiedObject.enproCode",
                },
              },
            },
            title: {
              type: "value",
              path: "BaseWork.Document.IdentifiedObject.name",
            },
            status_id: {
              type: "value",
              path: "BaseWork.Document.status.id",
            },
            Issuer: {
              type: "object",
              properties: {
                DocumentPersonRole: {
                  type: "object",
                  properties: {
                    PersonRole: {
                      type: "object",
                      properties: {
                        person_id: {
                          type: "value",
                          path: "BaseWork.Document.Issuer.DocumentPersonRole.PersonRole.Person.id",
                        },
                      },
                    },
                  },
                },
              },
            },
            author: {
              type: "value",
              path: "BaseWork.Document.Author.DocumentPersonRole.PersonRole.Person",
            },
            createdDateTime: {
              type: "value",
              path: "BaseWork.Document.createdDateTime",
            },
            lastModifiedDateTime: {
              type: "value",
              path: "BaseWork.Document.lastModifiedDateTime",
            },
          },
        },
        status_kind_id: {
          type: "value",
          path: "BaseWork.statusKind.id",
        },
        kind_id: {
          type: "value",
          path: "BaseWork.kind.id",
        },
        priority_id: {
          type: "value",
          path: "BaseWork.priority.id",
        },
        WorkLocation: {
          type: "object",
          properties: {
            id: {
              type: "id",
              path: "BaseWork.WorkLocation.id",
            },
            location_id: {
              type: "value",
              path: "BaseWork.WorkLocation.Location.id",
            },
          },
        },
        TimeSchedules: {
          type: "array",
          path: "BaseWork.TimeSchedules",
          items: {
            type: "object",
            properties: {
              id: {
                type: "id",
                path: "id",
              },
              TimeSchedule: {
                type: "object",
                properties: {
                  id: {
                    type: "id",
                    path: "TimeSchedule.id",
                  },
                  scheduleInterval: {
                    type: "object",
                    properties: {
                      id: {
                        type: "id",
                        path: "TimeSchedule.scheduleInterval.id",
                      },
                      start: {
                        type: "value",
                        path: "TimeSchedule.scheduleInterval.start",
                      },
                      end: {
                        type: "value",
                        path: "TimeSchedule.scheduleInterval.end",
                      },
                    },
                  },
                },
              },
              kind_id: {
                type: "value",
                path: "kind.id",
              },
            },
          },
        },
        WorkActivityRecords: {
          type: "array",
          path: "BaseWork.WorkActivityRecords",
          items: {
            type: "object",
            properties: {
              id: {
                type: "id",
                path: "id",
              },
              ActivityRecord: {
                type: "object",
                properties: {
                  id: {
                    type: "id",
                    path: "ActivityRecord.id",
                  },
                  createdDateTime: {
                    type: "value",
                    path: "ActivityRecord.createdDateTime",
                  },
                },
              },
              percentComplete: {
                type: "object",
                properties: {
                  id: {
                    type: "id",
                    path: "percentComplete.id",
                  },
                  value: {
                    type: "value",
                    path: "percentComplete.value",
                  },
                },
              },
            },
          },
        },
      },
    },
    contractorCost: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "contractorCost.id",
        },
        value: {
          type: "value",
          path: "contractorCost.value",
        },
      },
    },
    laborCost: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "laborCost.id",
        },
        value: {
          type: "value",
          path: "laborCost.value",
        },
      },
    },
    laborHours: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "laborHours.id",
        },
        value: {
          type: "value",
          path: "laborHours.value",
        },
      },
    },
    materialCost: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "materialCost.id",
        },
        value: {
          type: "value",
          path: "materialCost.value",
        },
      },
    },
    toolCost: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "toolCost.id",
        },
        value: {
          type: "value",
          path: "toolCost.value",
        },
      },
    },
    totalCost: {
      type: "value",
      path: "totalCost",
    },
    MaterialItems: {
      type: "array",
      path: "MaterialItems",
      items: updateWorkTaskMaterialItems,
    },
    OldWorkTask: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "OldWorkTask.id",
        },
        LaborItems: {
          type: "array",
          path: "OldWorkTask.LaborItems",
          items: updateWorkTaskLaborItem,
        },
        WorkEquipmentItems: {
          type: "array",
          path: "OldWorkTask.WorkEquipmentItems",
          items: updateWorkTaskWorkEquipmentItems,
        },
        MiscCostItems: {
          type: "array",
          path: "OldWorkTask.MiscCostItems",
          items: updateWorkTaskMiscCostItems,
        },
      },
    },
  },
};
