import { createWorkSchema } from "@/services/work/schemas";

export const parse_work_to_server = (value) => {
  let data = Object.assign({}, createWorkSchema);
  data.BaseWork.Document.title = value.title;
  data.BaseWork.Document.IdentifiedObject.name = value.title;
  data.BaseWork.Document.Issuer.DocumentPersonRole.PersonRole.person_id =
    value.issuerId;
  data.BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.start =
    value.dateWorkFrom + "T00:00:00.000000Z";
  data.BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.end =
    value.dateWorkTo + "T23:59:59.000000Z";
  data.BaseWork.kind_id = value.kindId;
  data.BaseWork.status_kind_id = value.statusKindId;
  data.BaseWork.priority_id = value.priorityId;
  data.BaseWork.WorkLocation.location_id = value.locationId;
  return data;
};

export const parse_work_to_server_for_update = (value) => {
  let data = Object.assign({}, value);
  data.BaseWork.Document.title = value.BaseWork.Document.title;
  data.BaseWork.Document.IdentifiedObject.name = value.BaseWork.Document.title;
  data.BaseWork.Document.Issuer.DocumentPersonRole.PersonRole.person_id =
    value.BaseWork.Document.Issuer.DocumentPersonRole.PersonRole.Person.id;
  data.BaseWork.kind_id = value.BaseWork.kind.id;
  data.BaseWork.status_kind_id = value.BaseWork.statusKind.id;
  data.BaseWork.priority_id = value.BaseWork.priority.id;
  data.BaseWork.WorkLocation.location_id =
    value.BaseWork.WorkLocation.Location.id;
  data.BaseWork.TimeSchedules[0].kind_id =
    value.BaseWork.TimeSchedules[0].kind.id; // костыль для бека
  delete data.Designs;
  delete data.WorkTasks;
  delete data.requestedDateTime;
  delete data.BaseWork.Document.status;
  delete data.BaseWork.Document.docStatus;
  delete data.BaseWork.Document.electronicAddress;
  delete data.BaseWork.kind;
  delete data.BaseWork.priority;
  delete data.BaseWork.statusKind;
  delete data.totalCost;
  return data;
};
